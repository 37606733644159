<template>
  <IconBase :viewBoxWidth="24" :viewBoxHeight="24">
    <path
      d="M20.84 11.55a.983.983 0 00-.73-.69l-6.89-1.6 1.75-7.01a.995.995 0 00-.48-1.11 1 1 0 00-1.2.16l-10 9.99c-.25.25-.35.63-.26.97.1.35.37.62.72.7l7.03 1.76-1.76 7.03A1.008 1.008 0 009.99 23c.26 0 .52-.1.72-.3l9.88-10.17c.25-.26.34-.63.24-.98h.01z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="currentColor"
    />
  </IconBase>
</template>
